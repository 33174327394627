@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

@mixin Gradation($color01: #d1d75e, $color02: #63bc6f) {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d1d75e+0,63bc6f+100 */
	background: $color01; /* Old browsers */
	background: -moz-linear-gradient(left,  $color01 0%, $color02 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  $color01 0%,$color02 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  $color01 0%,$color02 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color01', endColorstr='$color02',GradientType=1 ); /* IE6-9 */
}

%Hiraginomaru{
	font-family: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
	font-weight: normal;
}

%Nothing{
	font-family: 'Nothing You Could Do', cursive;
	font-weight: 400;
}

/*
トップページ
-------------------------------------*/
#Page{
	#MainImg{
		position: relative;
		#MainImgInner{
			position: relative;
			margin: 0 auto;
			padding-top: 0;
			h2, a{
				position: absolute;
				z-index: 10;
			}
			h2{
				text-align: left;
				&::after{
					content: "Tax accountant office";
				}
			}
			a{
				border-radius: 50%;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url("../img/contents/top/00_visuBanner.png");
				text-indent: -9999px;
			}
		}
		.owl-carousel{
			margin: 0 auto;
			width: 100%;
			li{
				background-repeat: no-repeat;
				background-position: center bottom, center center;
				background-size: 100% auto, cover;
			}
		}
	}
	#Main{
		#ContBox01{
			.titleTypeBasicCircle{
				&+ p{
					font-size: 14px;
					font-weight: normal;
					color: #888;
				}
			}
			.contSubBox01{
				.wrapBox{
					.imgBox{
						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}
			.contSubBox02{
                border-bottom: 1px solid #e6e6e6;
                .txtBox{
                    &.toolBox{
                        ul{
                            li{
                                background-position: left center;
                            }
                        }
                        b{
                            font-size: 20px;
                        }
                    }
                }
			}
            .contSubBox03{
                .owl-carousel02{
                    li{
                        border-radius: 8px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                        background-color:  black;
                    }
                    .owl-nav{
                        position: relative;
                        .owl-prev, .owl-next{
                            position: absolute;
                            display: block;
                            border-radius: 50%;
                            width: 34px;
                            height: 34px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 7px auto;
                            background-color: #e9eff9;
                            text-indent: -9999px;
                        }
                        .owl-prev{
                            background-image: url("../img/contents/arrowBlueThick_left.png")
                        }
                        .owl-next{
                            background-image: url("../img/contents/arrowBlueThick.png")
                        }
                    }
                    .owl-dots{
                        text-align: center;
                        font-size: 0;
                        .owl-dot{
                            display: inline-block;
                            border-radius: 50%;
                            margin: 0 6px;
                            width: 8px;
                            height: 8px;
                            background-color: #e9eff9;
                            &.active{
                                background-color: #7895c5;
                            }
                        }
                    }
                }
            }
		}
		#ContBox02{
			.contSubBox01{
				border-bottom: 1px solid #aab5d4;
				h4{
					opacity: .3;
					position: relative;
					@include Gradation(#5eb9d7, #6264a1);
					-webkit-background-clip: text;
					background-clip: text;
					text-fill-color: transparent;
					-webkit-text-fill-color: transparent;
					@extend %Nothing;
				}
				.listTypeBanner{
					li{
						&:last-of-type{
							margin: 0;
						}
					}
				}
			}
			.contSubBox02{
				border-bottom: 1px solid #aab5d4;
				&> ul{
					&> li{
						.titleTypeBasic{
							font-size: 32px;
							&::after{
								content: "";
								display: block;
								width: 70px;
								height: 70px;
								background-repeat: no-repeat;
								background-position: center center;
								background-size: 70px auto;
							}
						}
						&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
							margin-bottom: 20px;
							dl{
								dt{
									&.titleTypeSub{
										text-align: center;
										font-size: 18px;
									}
								}
								dd{
									ul{
										li{
											&:last-of-type{
												margin-bottom: 0;
											}
											a{
												position: relative;
												display: block;
												background-color: #7895c5;
												text-decoration: none;
												font-size: 16px;
												color: #fff;
												&::after{
													content: "";
													position: absolute;
													top: 50%;
													display: block;
													margin-top: -6px;
													width: 7px;
													height: 11px;
													background-repeat: no-repeat;
													background-position: center center;
													background-image: url("../img/contents/arrowWhiteM.png");
													background-size: 7px auto;
												}
											}
										}
									}	
								}
							}
						}
						&:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6){
							a{
								position: relative;
								display: block;
								text-decoration: none;
								.titleTypeBasic{
									display: block;
									margin-bottom: 0;
									line-height: 1;
									font-size: 32px;
								}
								&::after{
									content: "";
									position: absolute;
									background-repeat: no-repeat;
									background-position: center center;
									background-image: url("../img/contents/01_backofficeArrow.png");
								}
							}
						}
						&:nth-of-type(1){
							.titleTypeBasic{
								&::after{
									background-image: url("../img/contents/00_backoffice1.png");
								}
							}
						}
						&:nth-of-type(2){
							.titleTypeBasic{
								&::after{
									background-image: url("../img/contents/00_backoffice2.png");
								}
							}
						}
						&:nth-of-type(3){
							.titleTypeBasic{
								&::after{
									background-image: url("../img/contents/00_backoffice3.png");
								}
							}
						}
						&:nth-of-type(4){
							.titleTypeBasic{
								&::after{
									background-image: url("../img/contents/00_backoffice4.png");
								}
							}
						}
						&:nth-of-type(5){
							.titleTypeBasic{
								&::after{
									background-image: url("../img/contents/00_backoffice5.png");
								}
							}
						}
						&:nth-of-type(6){
							.titleTypeBasic{
								&::after{
									background-image: url("../img/contents/00_backoffice6.png");
								}
							}
						}
					}
				}
			}
			.contSubBox03{
				.litsTypeA{
					&> li{
						background-repeat: no-repeat;
						.titleTypeImg{
							border-bottom: 1px solid #e2e5ed;
							text-align: center;
						}
						.titleTypeSub{
							font-size: 18px;
						}
						&:last-of-type{
							margin: 0;
						}
						&:nth-of-type(1){
							background-image: url("../img/contents/top/00_tokuchoImg1.png");
							.titleTypeImg{
								img{
									width: 191px;
								}
							}
						}
						&:nth-of-type(2){
							background-image: url("../img/contents/top/00_tokuchoImg2.png");
							.titleTypeImg{
								img{
									width: 227px;
								}
							}
						}
						&:nth-of-type(3){
							background-image: url("../img/contents/top/00_tokuchoImg3.png");
							.titleTypeImg{
								img{
									width: 226px;
								}
							}
						}
					}
				}
			}
		}
		#ContBox03{
			.titleTypeBasic{
				text-align: left;
				a{
					position: relative;
					display: block;
					border: 1px solid #607fb3;
					width: 220px;
					height: 50px;
					line-height: 1;
					text-align: left;
					text-decoration: none;
					font-size: 16px;
					color: #607fb3;
					&::after{
						content: "";
						position: absolute;
						top: 50%;
						display: block;
						margin-top: -6px;
						width: 7px;
						height: 11px;
						background-repeat: no-repeat;
						background-position: center center;
						background-image: url("../img/contents/arrowBlueThick.png");
						background-size: 7px auto;
					}
				}
			}
			.listTypeBlog{
				li{
					a{
						dl{
							.title{
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#Page{
		#MainImg{
			height: 638px;
			#MainImgInner{
				width: 1000px;
				h2{
					top: 198px;
					left: 9px;
					width: 690px;
					line-height: 83px;
					font-size: 52px;
					span{
						font-size: 42px;
					}
					&::after{
						bottom: -82px;
						left: -14px;
					}
				}
				a{
					top: 179px;
					right: -50px;
					width: 321px;
					height: 321px;
					background-size: 321px auto;
					&:hover{
						opacity: .7;
					}
				}
			}
			.owl-carousel{
				position: absolute;
				top: 0;
				left: 0;
				min-width: 1000px;
				li{
					height: 638px;
					&.list01{
						 background-image: url(../img/contents/wall_01.png), url(../img/contents/top/00_visuA.jpg);
					}
					&.list02{
						 background-image: url(../img/contents/wall_01.png), url(../img/contents/top/00_visuB.jpg);
					}
					&.list03{
						 background-image: url(../img/contents/wall_01.png), url(../img/contents/top/00_visuC.jpg);
					}
				}
			}
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 120px;
				}
				.titleTypeBasicCircle{
					&::before{
						background-image: url("../img/contents/00_bgKyokusenGreen.png");
					}
					&+ p{
						margin-bottom: 108px;
						letter-spacing: .05em;
					}
				}
				.contSubBox01{
					padding-bottom: 37px;
					.wrapBox{
						.imgBox{
							float: left;
							width: 380px;
						}
						.txtBox{
							float: right;
							padding-top: 18px;
							width: 560px;
							p{
								margin-bottom: 28px;
							}
							.listTypeContact{
								.telBox, .formBox{
									width: 270px;
									a{
										width: 270px;
									}
								}
								.telBox{
									a{
										padding-right: 18px;
										padding-left: 18px;
									}
								}
							}
						}
					}
				}
				.contSubBox02{
                    padding-bottom: 34px;
            		.txtBox{
                        padding-top: 22px;
                        &.toolBox{
                            b{
                                margin: 0 auto 30px;;
                                border-radius: 8px;
                                padding-top: 11px;
                                width: 489px;
                                height: 40px;
                                letter-spacing: 6px;
                            }
                            ul{
                                margin-left: 25px;
                                li{
                                    margin-bottom: 26px;
                                    padding-top: 14px;
                                    padding-left: 80px;
                                    min-height: 60px;
                                    background-size: 60px auto;
                                    font-size: 24px;
                                    &.list01{
                                        width: 220px;
                                    }
                                    &.list02{
                                        width: 267px;
                                    }
                                    &.list03{
                                        width: 208px;
                                    }
                                    &.list04{
                                        width: 257px;
                                    }
                                }
                            }
                        }
                    }
				}
				.contSubBox03{
                    padding-top: 60px;
                    .owl-carousel02{
                        li{
                            width: 235px;
                            height: 167px;
                        }
                        .owl-nav{
                            .owl-prev, .owl-next{
                                top: -100px;
                            }
                            .owl-prev{
                                left: -60px;
                            }
                            .owl-next{
                                right: -60px;
                            }
                        }
                        .owl-dots{
                            margin-top: 30px;
                        }
                    }
                }
			}
			#ContBox02{
				.innerBasic{
					padding-top: 138px;
					padding-bottom: 60px;
				}
				.titleTypeBasicCircle{
					margin-bottom: 95px;
					&::before{
						top: -89px;
					}
				}
				.contSubBox01{
					padding-bottom: 100px;
					h4{
						top: -15px;
						right: -40px;
						text-align: right;
						letter-spacing: .002em;
						font-size: 42px;
					}
					.listTypeBanner{
						margin-top: -30px;
						margin-bottom: 77px;
						text-align: left;
						li{
							display: inline-block;
							margin-right: 24px;
						}
					}
					.boxTypeBasic{
						padding-top: 23px;
						padding-bottom: 40px;
						.titleTypeBasic{
							margin-bottom: 17px;
						}
						.listTypeBanner{
							margin: 0;
							text-align: center;
							li{
								display: inline-block;
								margin-right: 24px;
							}
						}
					}
				}
				.contSubBox02{
					padding-top: 82px;
					padding-bottom: 100px;
					.titleTypeBasic{
						margin-bottom: 64px;
						line-height: 68px;
						font-size: 36px;
					}
					&> ul{
						&::after {
							content: "";
							display: block;
							clear: both;
						}
						&> li{
							float: left;
							margin-right: 20px;
							width: 320px;
							.titleTypeBasic{
								letter-spacing: 5px;
								&::after{
									margin: 5px auto 0;
								}
							}
							&:nth-of-type(3n){
								margin-right: 0;
							}
							&:nth-of-type(3n+1){
								clear: both;
							}
							&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
								margin-bottom: 20px;
								padding: 32px 30px 0;
								height: 762px;
								.titleTypeBasic{
									margin-bottom: 22px;
								}
								p{
									margin-bottom: 20px;
								}
								dl{
									dt{
										&.titleTypeSub{
											margin-bottom: 28px;
											letter-spacing: 0;
										}
									}
									dd{
										ul{
											li{
												margin-bottom: 16px;
												a{
													border-radius: 4px;
													padding: 14px 27px 0 20px;
													height: 50px;
													letter-spacing: .1em;
													&:hover{
														opacity: .7;
													}
													&::after{
														right: 20px;
													}
												}
											}
										}	
									}
								}
							}
							&:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6){
								a{
									padding: 50px 10px 0;
									height: 214px;
									.titleTypeBasic{
										&::after{
											margin-top: 23px;
										}
									}
									&::after{
										right: 8px;
										bottom: 8px;
										width: 35px;
										height: 35px;
										background-size: 35px auto;
									}
									&:hover{
										&::after{
											opacity: .7;
										}
									}
								}
							}
						}
					}
				}
				.contSubBox03{
					padding-top: 82px;
					.titleTypeBasic{
						margin-bottom: 64px;
						line-height: 68px;
						font-size: 36px;
					}
					.litsTypeA{
						&::after {
							content: "";
							display: block;
							clear: both;
						}
						&> li{
							float: left;
							margin-right: 20px;
							padding: 44px 30px 0;
							width: 320px;
							height: 710px;
							background-position: center bottom 40px;
							.titleTypeImg{
								margin-bottom: 30px;
								padding-bottom: 40px;
							}
							.titleTypeSub{
								margin-bottom: 10px;
								letter-spacing: 0;
							}
							.listTypeSmall{
								margin-top: -14px;
							}
							&:nth-of-type(1){
								background-size: 270px auto;
							}
							&:nth-of-type(2){
								background-size: 220px auto;
								.titleTypeSub{
									letter-spacing: -.07em;
								}
							}
							&:nth-of-type(3){
								background-size: 192px auto;
							}
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-top: 220px;
					padding-bottom: 120px;
				}
				.titleTypeBasic{
					float: left;
					margin-top: -14px;
					width: 300px;
					font-size: 36px;
					a{
						margin-top: 17px;
						border-radius: 4px;
						padding: 16px 0 0 19px;
						&:hover{
							opacity: .7;
						}
						&::after{
							right: 20px;
						}
					}
				}
				.listTypeBlog{
					float: right;
					width: 700px;
				}
			}
		}
	}
}
@media print, screen and (max-width: 1100px) {
	#Page{
		#MainImg{
			#MainImgInner{
				a{
					right: 0;
				}
			}
		}
		#Main{
			#ContBox01{
				.contSubBox03{
                    .owl-carousel02{
                        .owl-nav{
                            .owl-prev{
                                left: 10px;
                            }
                            .owl-next{
                                right: 10px;
                            }
                        }
                    }
                }
			}
			#ContBox02{
				.contSubBox01{
					h4{
						right: 0;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 1000px) {
	#Page{
		#MainImg{
			#MainImgInner{
				h2{
					&::after{
						left: 27px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#Page{
		#MainImg{
			height: 588px;
			#MainImgInner{
				h2{
					top: 40px;
					left: 50%;
					margin-left: -160px;
					padding-left: 25px;
					max-width: 350px;
					line-height: 53px;
					font-size: 41px;
					span{
						font-size: 33px;
					}
					&::after{
						position: relative;
						margin-top: 0;
						bottom: -12px;
						left: -41px;
					}
				}
				a{
					top: 289px;
					right: 50%;
					margin-right: -120px;
					width: 240px;
					height: 240px;
					background-size: 240px auto;
				}
			}
			.owl-carousel{
				li{
					height: 588px;
					&.list01{
						  background-image: url(../img/contents/wall_01_sp.png), url(../img/contents/top/00_mainimage_visuA_sp.jpg);
					}
					&.list02{
						 background-image: url(../img/contents/wall_01_sp.png), url(../img/contents/top/00_mainimage_visuB_sp.jpg);
					}
					&.list03{
						background-image: url(../img/contents/wall_01_sp.png), url(../img/contents/top/00_mainimage_visuC_sp.jpg);
					}
				}
			}
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 90px;
				}
				.titleTypeBasicCircle{
					&::before{
						background-image: url("../img/contents/00_bgKyokusenGreen_sp.png");
					}
					&+ p{
						margin-top: -37px;
						margin-bottom: 52px;
						line-height: 28px;
						letter-spacing: 0;
					}
				}
				.contSubBox01{
					padding-bottom: 50px;
					.wrapBox{
						.txtBox{
							padding-top: 41px;
							p{
								margin-bottom: 40px;
							}
						}
					}
				}
				.contSubBox02{
                    padding-bottom: 28px;
                    .txtBox{
                        &.toolBox{
                            margin: 0 auto;
                            max-width: 345px;
                            b{
                                margin: 0 auto 18px;
                                border-radius: 8px;
                                padding: 9px 5px;
                                letter-spacing: 4px;
                            }
                            ul{
                                li{
                                    margin-bottom: 22px;
                                    padding-top: 11px;
                                    padding-left: 55px;
                                    min-height: 45px;
                                    background-size: 45px auto;
                                    font-size: 20px;
                                    &:nth-of-type(2n+1){
                                        width: 42%;
                                    }
                                    &:nth-of-type(2n){
                                        width: 58%;
                                    }
                                }
                            }
                        }
                    }
				}
				.contSubBox03{
                    padding-top: 50px;
                    .owl-carousel02{
                        li{
                            padding-top: 71.0638297%;
                            height: 0;
                        }
                        .owl-nav{
                            .owl-prev, .owl-next{
                                top: 22px;
                            }
                            .owl-prev{
                                left: 55px;
                            }
                            .owl-next{
                                right: 55px;
                            }
                        }
                        .owl-dots{
                            margin-top: 36px;
                            .owl-dot{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
			}
			#ContBox02{
				.innerBasic{
					padding-top: 84px;
					padding-bottom: 80px;
				}
				.titleTypeBasicCircle{
					margin-bottom: 38px;
					line-height: 60px;
					font-size: 32px;
					&::before{
						top: -40px;
					}
				}
				.contSubBox01{
					padding-bottom: 81px;
					h4{
						text-align: center;
						letter-spacing: .002em;
						font-size: 26px;
					}
					.listTypeBanner{
						margin-top: 26px;
						margin-bottom: 59px;
						text-align: center;
						li{
							margin-right: 0;
							margin-bottom: 25px;
							&.list01{
								margin-bottom: 8px;
								img{
									display: inline-block;
									max-width: 320px;
								}
							}
						}
					}
					.boxTypeBasic{
						padding-top: 39px;
						padding-bottom: 28px;
						.titleTypeBasic{
							margin-bottom: 31px;
						}
						.listTypeBanner{
							margin: 0;
							text-align: center;
							li{
								margin-right: auto;
								margin-left: auto;
								max-width: 234px;
								text-align: center;
								&.list02{
									margin-bottom: -2px;
								}
							}
						}
					}
				}
				.contSubBox02{
					padding-top: 65px;
					padding-bottom: 59px;
					.titleTypeBasic{
						margin-bottom: 46px;
						line-height: 60px;
						font-size: 32px;
					}
					&> ul{
						&> li{
							margin-bottom: 20px;
							.titleTypeBasic{
								letter-spacing: 5px;
								&::after{
									margin: 9px auto 0;
								}
							}
							&:nth-of-type(3n){
								margin-right: 0;
							}
							&:nth-of-type(3n+1){
								clear: both;
							}
							&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
								margin-bottom: 20px;
								padding: 23px 30px 40px;
								.titleTypeBasic{
									margin-bottom: 22px;
								}
								p{
									margin-bottom: 20px;
								}
								dl{
									dt{
										&.titleTypeSub{
											margin-bottom: 28px;
											letter-spacing: 0;
										}
									}
									dd{
										ul{
											li{
												margin-bottom: 16px;
												a{
													border-radius: 4px;
													padding: 14px 27px 14px 20px;
													letter-spacing: .1em;
													&::after{
														right: 20px;
													}
												}
											}
										}	
									}
								}
							}
							&:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6){
								a{
									padding: 39px 10px 39px;
									.titleTypeBasic{
										&::after{
											margin-top: 23px;
										}
									}
									&::after{
										right: 9px;
										bottom: 9px;
										width: 34px;
										height: 34px;
										background-size: 34px auto;
									}
								}
							}
						}
					}
				}
				.contSubBox03{
					padding-top: 64px;
					.titleTypeBasic{
						margin-bottom: 46px;
						line-height: 60px;
						font-size: 32px;
					}
					.litsTypeA{
						&> li{
							margin-bottom: 20px;
							padding: 40px 30px 126px;
							background-position: center bottom 40px;
							.titleTypeImg{
								margin-bottom: 30px;
								padding-bottom: 40px;
							}
							.titleTypeSub{
								margin-bottom: 19px;
								letter-spacing: 0;
							}
							.listTypeSmall{
								margin-top: -11px;
							}
							&:nth-of-type(1){
								background-size: 270px auto;
							}
							&:nth-of-type(2){
								padding-bottom: 143px;
								background-size: 220px auto;
								.titleTypeSub{
									letter-spacing: -.07em;
								}
							}
							&:nth-of-type(3){
								background-size: 192px auto;
							}
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-top: 103px;
					padding-bottom: 105px;
				}
				.titleTypeBasic{
					margin-top: 23px;
					margin-bottom: 59px;
					font-size: 32px;
					a{
						margin-top: 17px;
						border-radius: 4px;
						padding: 16px 0 0 19px;
						&::after{
							right: 20px;
						}
					}
				}
				.listTypeBlog{
				}
			}
		}
	}
}
@media print, screen and (max-width: 390px) {
	#Page{
		#Main{
			#ContBox01{
				.contSubBox03{
                    .owl-carousel02{
                        .owl-nav{
                            .owl-prev{
                                left: 30px;
                            }
                            .owl-next{
                                right: 30px;
                            }
                        }
                    }
                }
			}
		}
	}
}
@media print, screen and (max-width: 340px) {
	#Page{
		#Main{
			#ContBox01{
				.contSubBox03{
                    .owl-carousel02{
                        .owl-nav{
                            .owl-prev{
                                left: 0;
                            }
                            .owl-next{
                                right: 0;
                            }
                        }
                    }
                }
			}
		}
	}
}
/*
freee
-------------------------------------*/
#PageFreee.pageIndex{
	#Container{
		background-color: #e9eff9;
	}
	#MainImg{
		h2{
			&::after{
				content: "Free support";
			}
		}
	}
	#Main{
		#ContBox01{
			.contSubBox01{
				ul{
					li{
						dl{
							.titleTypeSub{
								span{
									background-repeat: no-repeat;
									background-size: 90px auto;
								}
							}
						}
						&.list01{
							dl{
								.titleTypeSub{
									span{
										background-image: url("../img/contents/01_tokucho1.png");
									}
								}
							}
						}
						&.list02{
							dl{
								.titleTypeSub{
									span{
										background-image: url("../img/contents/01_tokucho2.png");
									}
								}
							}
						}
						&.list03{
							dl{
								.titleTypeSub{
									span{
										background-image: url("../img/contents/01_tokucho3.png");
									}
								}
							}
						}
						&.list04{
							dl{
								.titleTypeSub{
									span{
										background-image: url("../img/contents/01_tokucho4.png");
									}
								}
							}
						}
					}
				}
			}
			.contSubBox02{
				figure{
					img{
						width: 100%;
						height: auto;
					}
				}
				.bottonTypeBlue{
					b, span{
						display: block;
					}
					b{
						font-weight: bold;
					}
					span{
						background-repeat: no-repeat;
						background-position: right center;
						background-image: url("../img/contents/arrowWhiteM.png");
						background-size: 7px auto;
						letter-spacing: .1em;
					}
					&::before{
						content: "";
						display: block;
						width: 160px;
						height: 55px;
						background-repeat: no-repeat;
						background-position: left top;
						background-image: url("../img/contents/logo_01.png");
						background-size: 160px auto;
					}
					&::after{
						display: none;
					}
				}
			}
			.contSubBox03{
				.wrapBox{
					.subBox{
						.titleTypeSub{
							b{
								display: block;
							}
						}
						table{
							width: 100%;
							border: 1px solid #aab5d4;
							tr{
								th, td{
									border: 1px solid #aab5d4;
									vertical-align: middle;
									font-size: 14px;
									line-height: 28px;
								}
								.th01, .td01{
									width: 40px;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: auto 100%;
								}
								th{
									background-color: #eef0f5;
									text-align: center;
								}
								td{
									&.td02{
										line-height: 22px;
									}
									&.td03{
										text-align: center;
									}
								}
							}
						}
						img{
							width: 100%;
							height: auto;
						}
						.titleTypeSubLeft{
							line-height: 32px;
							font-size: 16px;
						}
						&.subBox01{
							table{
								tr{
									th{
										&.th01{	background-image: url("../img/contents/free/01_img2JyuraiBlue.png");}
									}
									td{
										&.td01{	background-image: url("../img/contents/free/01_img2JyuraiPurple.png");}
									}
								}
							}

						}
						&.subBox03{
							table{
								tr{
									th{
										&.th01{	background-image: url("../img/contents/free/01_img2CloudBlue.png");}
									}
									td{
										&.td01{	background-image: url("../img/contents/free/01_img2CloudPurple.png");}
									}
								}
							}
						}
					}
				}
			}
		}
		#ContBox02{
			.contSubBox01{
				.boxTypeBorder{
					.titleTypeImg{
						background-repeat: no-repeat;
					}
					&.list01{
						.titleTypeImg{
							background-image:  url("../img/contents/01_tokucho3.png");
						}
					}
					&.list02{
						.titleTypeImg{
							background-image: url("../img/contents/01_tokucho1.png");
						}
					}
					&.list03{
						.titleTypeImg{
							background-image:  url("../img/contents/01_tokucho2.png");
						}
					}
				}
				.subBox01{
					p{
						margin-bottom: 0;
						font-size: 20px;
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageFreee.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/free/lower_pagettl1.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 140px;
				}
				.contSubBox01{
					ul{
						margin-bottom: -23px;
						&::after{
							content: "";
							display: block;
							clear: both;
						}
						li{
							float: left;
							width: 50%;
							dl{
								.titleTypeSub{
									display: table-cell;
									width: 412px;
									span{
										display: inline-block;
										padding: 30px 0 30px 108px;
										background-position: left center;
										vertical-align: middle;
									}
								}
								dd{
									margin-top: 13px;
								}
							}
							&:nth-of-type(2n+1){
								clear: both;
								padding-right: 28px;
							}
							&:nth-of-type(2n){
								padding-left: 28px;
							}
							&:nth-of-type(1),&:nth-of-type(2){
								margin-bottom: 35px;
							}
						}
					}
				}
				.contSubBox02{
					figure{
						margin-top: 63px;
						margin-bottom: 63px;
						.titleTypeSubLeft{
							margin-bottom: 50px;
						}
					}
					.bottonTypeBlue{
						padding: 33px 0 0 201px;
						height: 120px;
						b{
							font-size: 20px;
						}
						span{
							display: inline-block;
							margin-top: 4px;
							padding: 5px 30px 5px 0;
							letter-spacing: .1em;
							font-size: 16px;
						}
						&::before{
							content: "";
							position: absolute;
							top: 37px;
							left: 30px;
						}
					}
				}
				.contSubBox03{
					.wrapBox{
						margin-top: 60px;
						margin-bottom: -25px;
						.subBox{
							float: left;
							margin-right: 50px;
							width: 240px;
							&:last-of-type{
								margin-right: 0;
							}
							.titleTypeSub{
								margin-bottom: 10px;
								b{
									margin-top: 15px;
									letter-spacing: .05em;
								}
							}
							table{
								margin-top: 20px;
								margin-bottom: 21px;
								tr{
									th{
										&.th02{
											padding-top: 35px;
											padding-bottom: 35px;
											width: 120px;
										}
									}
									td{
										padding: 18px 6px;
									}
								}
							}
							&.subBox02{
								margin-top: 10px;
								width: 300px;
							}
							&.subBox03{
								table{
									tr{
										th{
											&.th02{
												padding-top: 31px;
												padding-bottom: 31px;
											}
										}
										td{
											&.td02{
												padding: 16px 9px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-top: 200px;
					padding-bottom: 80px;
				}
				h3.titleTypeBasic{
					margin-bottom: 62px;
					line-height: 68px;
					font-size: 36px;
				}
				.contSubBox01{
					.boxTypeBorder{
						padding-top: 39px;
						padding-bottom: 35px;
						.titleTypeImg{
							margin-bottom: 22px;
							padding: 21px 0 10px 79px;
							background-position: left top;
							background-size: 60px auto;
						}
						&.list01{
							.titleTypeImg img{	width: 262px;}
						}
						&.list02{
							.titleTypeImg img{	width: 758px;}
						}
						&.list03{
							.titleTypeImg img{	width: 202px;}
						}
					}
					.subBox01{
						border-radius: 16px;
						padding-top: 27px;
						padding-bottom: 27px;
						p{
							line-height: 40px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFreee.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/free/lower_mainimage_sp_01.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 80px;
				}
				.contSubBox01{
					.titleTypeBasic{
						margin-bottom: 39px;
					}
					ul{
						margin-bottom: -23px;
						li{
							margin-bottom: 36px;
							&:last-of-type{
								margin-bottom: 0;
							}
							dl{
								.titleTypeSub{
									margin-bottom: 9px;
									span{
										display: block;
										padding-top: 107px;
										background-position: center top;
									}
								}
								dd{
									margin-top: 13px;
								}
							}
						}
					}
				}
				.contSubBox02{
					figure{
						margin-top: 32px;
						margin-bottom: 13px;
						.titleTypeSubLeft{
							margin-bottom: 33px;
							b{
								display: block;
							}
						}
					}
					.bottonTypeBlue{
						margin-top: 45px;
						padding: 31px 4px 31px 20px;
						b{
							line-height: 34px;
							font-size: 18px;
						}
						span{
							display: inline-block;
							margin-top: 14px;
							padding: 5px 23px 5px 0;
							letter-spacing: .05em;
							font-size: 14px;
						}
						&::before{
							margin: 0 auto 10px;
							padding-left: 14px;
						}
					}
				}
				.contSubBox03{
					.wrapBox{
						margin-top: 35px;
						margin-bottom: -20px;
						.subBox{
							.titleTypeSub{
								margin-bottom: -2px;
								b{
									margin-top: 5px;
									letter-spacing: .05em;
								}
							}
							.titleTypeSubLeft{
								margin-bottom: 7px;
								letter-spacing: 1px;
							}
							table{
								margin-top: 24px;
								margin-bottom: 21px;
								tr{
									th{
										&.th02{
											padding-top: 35px;
											padding-bottom: 35px;
											width: 53%;
										}
									}
									td{
										padding: 18px 6px;
										text-align: center;
									}
								}
							}
							&.subBox02{
								margin-top: 50px;
								margin-bottom: 50px;
								img{
									margin: 0 auto;
									max-width: 320px;
								}
							}
							&.subBox03{
								table{
									tr{
										th{
											&.th02{
												padding-top: 31px;
												padding-bottom: 31px;
											}
										}
										td{
											&.td02{
												padding: 38px 9px;
												text-align: justify;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-top: 78px;
					padding-bottom: 60px;
				}
				h3.titleTypeBasic{
					margin-bottom: 44px;
					line-height: 60px;
					font-size: 32px;
				}
				.contSubBox01{
					.boxTypeBorder{
						padding-top: 29px;
						padding-bottom: 14px;
						.titleTypeImg{
							padding-top: 81px;
							margin-bottom: 22px;
							background-position: center top;
							background-size: 60px auto;
							img{
								max-width: 100%;
							}
						}
						&.list01{
							.titleTypeImg img{	width: 262px;}
						}
						&.list02{
							.titleTypeImg img{	width: 283px;}
						}
						&.list03{
							.titleTypeImg img{	width: 202px;}
						}
					}
					.subBox01{
						border-radius: 16px;
						padding: 32px 30px 29px;
						p{
							line-height: 34px;
						}
					}
				}
			}
		}
	}
}

/*
起業支援
-------------------------------------*/
#PageSupport.pageIndex{
	#MainImg{
		h2{
			&::after{
				content: "Entrepreneurship support";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
	#Main{
		#ContBox01{
			.contSubBox01{
				.subBox01{
					ul{
						li{
							background-color: #eaf6d7;
							&:last-of-type{
								margin-bottom: 0;
							}
							dl{
								dt, dd{
									text-align: center;
								}
								dt{
									color: #659914;
								}
								dd{
									color: #7b6cbc;
									span{
										font-weight: bold;
									}
								} 
							}
						}
					}
				}
				.subBox02{
					.titleTypeSub{
						font-size: 16px;
					}
				}
			}
			.contSubBox02{
				.subBox02{
					.box01, .box02{
						position: relative;
						&::after{
							content: "";
							position: absolute;
							left: 50%;
							display: block;
						}
					}
					.box01{
						&::after{
							margin-left: -6px;
							width: 13px;
							background-color: #607fb3;
						}
					}
					.box02{
						&::after{
							margin-left: -15px;
							width: 31px;
							background-repeat: no-repeat;
							background-position: center top;
							background-image: url("../img/contents/support/arrow_01.png");
							background-size: 31px auto;
						}
					}
				}
			}
			.contSubBox03{
				.subBox01{
					.wrapBox{
						.box{
							.titleTypeImg{
								text-align: center;
							}
							p:first-of-type{
								background-repeat: no-repeat;
							}
						}
						.box01{
							background-color: #eff4fc;
							p:first-of-type{
								background-image: url("../img/contents/02_simulation1.png");
							}
						}
						.box02{
							background-color: #f2f2f2;
							p:first-of-type{
								background-image: url("../img/contents/02_simulation2.png");
							}
						}
					}
				}
			}
			.contSubBox04{
				.subBox01{
					.wrapBox{
						.box{
							table{
								tbody{
									tr{
										td{
											border-top: none;
											.listTypeBasic{
												dt{	font-weight: normal;}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.contSubBox05{
				.subBox01{
					.colorBox{
						margin-top: 51px;
						padding-top: 24px;
						padding-bottom: 40px;
						.titleTypeBasic{
							margin-bottom: 17px;
							letter-spacing: 0;
						}
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageSupport.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/support/lower_pagettl2.jpg");
			h2{
				&::after{
					margin-left: -29px;
				}
			}
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 140px;
				}
				.titleTypeBasic{
					margin-bottom: 59px;
				}
				.contSubBox01{
					.subBox01{
						margin-bottom: 69px;
						ul{
							margin-top: 41px;
							li{
								margin-bottom: 10px;
								border-radius: 8px;
								padding: 23px 0;
								dl{
									font-size: 0;
									dt, dd{
										display: inline-block;
										width: 50%;
										font-size: 16px;
									}
									dt{
										padding-left: 91px;
										width: 480px;
										text-align: left;
									}
									dd{
										position: relative;
										width: 345px;
										&::before{
											content: "";
											position: absolute;
											top: 1px;
											display: block;
											border-radius: 50%;
											border: 1px solid #7b6cbc; 
											width: 17px;
											height: 17px;
										}
									}
								}
								&.list01{
									dl{
										dd::before{
											left: 19px;
										}
									}
								}
								&.list02, &.list03, &.list04, &.list05{
									dl{
										dd::before{
											right: 15px;
										}
									}
								}
							}
	
						}
					}
					.subBox02{
						.titleTypeSub{
							margin-bottom: 16px;
						}
						.contactBox{
							&> p + p{
								display: inline;
								span{
									padding-left: 0;
								}
							}
						}
					}
				}
				.contSubBox02{
					.subBox01{
						margin-top: -40px;
					}
					.subBox02{
						.box{
							padding-bottom: 15px;
							.titleTypeSub{
								margin-bottom: 24px;
							}
							.colorBox {
								margin-bottom: 21px;
							}
						}
						.box01, .box02{
							margin-bottom: 60px;
							&::after{
								bottom: -61px;
								height: 60px;
							}
						}
						.box02{
							.titleTypeSub{
								margin-bottom: 8px;
							}
						}
						.box03{
							margin-bottom: 55px;
						}
					}
				}
				.contSubBox03{
					.subBox01{
						margin-top: -40px;
						.wrapBox{
							margin-top: 51px;
							margin-bottom: 56px;
							.box{
								float: left;
								margin-right: 40px;
								border-radius: 8px;
								padding: 41px 38px 15px;
								width: 420px;
								p:first-of-type{
									margin-bottom: 18px;
									padding-left: 120px;
									background-position: left center;
									background-size: 90px auto;
								}
							}
							.box01{
								.titleTypeImg{
									img{
										width: 242px;
									}
								}
							}
							.box02{
								margin-right: 0;
								.titleTypeImg{
									img{
										width: 268px;
									}
								}
							}
						}
						.contactBox{
							&> p + p{
								display: inline;
								span{
									padding-left: 0;
								}
							}
						}
					}
				}
				.contSubBox04{
					.subBox01{
						.wrapBox{
							margin-top: 48px;
							margin-bottom: 59px;
							.box{
								float: left;
								width: 50%;
								table{
									thead{
										tr{
											th{
												padding: 14px 10px 12px;
											}
										}
									}
									tbody{
										tr{
											td{
												padding-bottom: 0;
												height: 479px;
											}
										}
									}
								}
							}
							.box01{
								table{
									border-top-right-radius: 0;
									border-bottom-right-radius: 0;
									border-right: none;
									thead{
										tr{
											th{
												border-top-right-radius: 0;
												border-right: none;
											}
										}
									}
									tbody{
										tr{
											td{
												border-right: none;
												border-top-right-radius: 0;
											}
										}
									}
								}
							}
							.box02{
								table{
									border-top-left-radius: 0;
									border-bottom-left-radius: 0;
									thead{
										tr{
											th{
												border-top-left-radius: 0;
											}
										}
									}
									tbody{
										tr{
											td{
												border-top-left-radius: 0;
											}
										}
									}
								}
							}
						}
						.contactBox{
							&> p + p{
								display: inline;
								span{
									padding-left: 0;
								}
							}
						}
					}
				}
				.contSubBox05{
					.subBox01{
						.colorBox{
							margin-top: 51px;
							padding-top: 24px;
							padding-bottom: 40px;
							.titleTypeBasic{
								margin-bottom: 17px;
								letter-spacing: 0;
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageSupport.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/free/lower_mainimage_sp_01.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.titleTypeBasic{
					margin-bottom: 53px;
				}
				.contSubBox01{
					.subBox01{
						margin-bottom: 34px;
						ul{
							margin-top: 31px;
							li{
								margin-bottom: 10px;
								border-radius: 8px;
								padding: 20px 0 23px;
								dl{
									dt, dd{
										font-size: 14px;
									}
									dt{
										margin-bottom: 6px;
									}
									dd{
										&::before, &::after{
											position: relative;
											top: 5px;
											display: inline-block;
											border-radius: 50%;
											border: 1px solid #7b6cbc; 
											width: 19px;
											height: 19px;
										}
									}
								}
								&.list01{
									dl{
										dd::before{
											content: "";
											left: -2px;
										}
									}
								}
								&.list02, &.list03, &.list04, &.list05{
									dl{
										dd::after{
											content: "";
											right: -2px;
										}
									}
								}
							}
	
						}
					}
					.subBox02{
						.titleTypeSub{
							margin-bottom: 20px;
							line-height: 32px;
						}
					}
				}
				.contSubBox02{
					.subBox01{
						margin-top: -31px;
					}
					.subBox02{
						.box{
							border-radius: 8px;
							padding-bottom: 15px;
							.titleTypeSub{
								margin-bottom: 24px;
							}
							.colorBox {
								margin-bottom: 24px;
								border-radius: 8px;
							}
						}
						.box01, .box02{
							margin-bottom: 40px;
							&::after{
								bottom: -61px;
								height: 60px;
							}
						}
						.box02{
							margin-bottom: 60px;
							.titleTypeSub{
								margin-bottom: 12px;
							}
						}
						.box03{
							margin-bottom: 25px;
						}
					}
				}
				.contSubBox03{
					.subBox01{
						margin-top: -32px;
						.wrapBox{
							margin-top: 51px;
							margin-bottom: 56px;
							.box{
								border-radius: 8px;
								padding: 41px 20px 15px;
								.titleTypeImg{
									.pc_display_inline{
										display: none;
									}
								}
								p:first-of-type{
									margin-top: -12px;
									margin-bottom: 11px;
									padding-left: 89px;
									background-position: left 7px;
									background-size: 68px auto;
								}
							}
							.box01{
								margin-bottom: 30px;
								.titleTypeImg{
									img{
										width: 242px;
									}
								}
							}
							.box02{
								margin-right: 0;
								.titleTypeImg{
									img{
										width: 150px;
									}
								}
							}
						}
					}
				}
				.contSubBox04{
					.subBox01{
						.wrapBox{
							margin-top: 31px;
							margin-bottom: 41px;
							.box{
								table{
									thead{
										tr{
											th{
												padding: 14px 10px 12px;
											}
										}
									}
								}
							}
							.box01{
								table{
									border-bottom-left-radius: 0;
									border-bottom-right-radius: 0;
									border-bottom: none;
									tbody{
										tr{
											td{
												border-bottom: none;
												border-bottom-right-radius: 0;
												border-bottom-right-radius: 0;
											}
										}
									}
								}
							}
							.box02{
								table{
									border-top-right-radius: 0;
									border-top-left-radius: 0;
									thead{
										tr{
											th{
												border-top-right-radius: 0;
												border-top-left-radius: 0;
											}
										}
									}
								}
							}
						}
					}
				}
				.contSubBox05{
					.subBox01{
						.colorBox{
							margin-top: 30px;
							margin-bottom: 32px;
							border-radius: 8px;
							padding-top: 39px;
							padding-bottom: 27px;
							.titleTypeBasic{
								margin-bottom: 29px;
								letter-spacing: 0;
							}
							.listTypeBanner{
								li{
									&.list01{
										margin-bottom: -4px;
									}
									&.list03{
										margin-bottom: -3px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
会社設立
-------------------------------------*/
#PageEstablishment.pageIndex{
	#MainImg{
		h2{
			&::after{
				content: "Company formation";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
	#Main{
		#ContBox01{
			.contSubBox01{
				.boxTypeBorder{
					.colorBox{
						background-color: #eaf6d7;
						color: #659914;
					}
				}
				.listTypeBasic{
					.titleTypeSubLeft{
						letter-spacing: 0;
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageEstablishment.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/establishment/lower_pagettl3.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 120px;
				}
				.titleTypeBasicCircle{
					&+ p{
						margin-top: -70px;
						margin-bottom: 68px;
					}
				}
				.contSubBox01{
					.boxTypeBorder{
						border-radius: 8px;
						padding-bottom: 15px;
						.colorBox{
							margin-bottom: 20px;
						}
						.listTypeBasic{
							.titleTypeSubLeft{
								margin-bottom: 2px;
							}
						}
					}
					.subBox01{
						padding-top: 40px;
					}
					.subBox02{
						margin-bottom: 51px;
						padding-top: 33px;
						.titleTypeSub{
							margin-bottom: 24px;
						}
					}
					.contactBox{
						margin-top: 51px;
					}
				}
				.contSubBox02{
					.subBox01{
						margin-top: -12px;
						margin-bottom: 60px;
						& > p{
							margin-bottom: 32px;
						}
					}
					.tableTypeBasic{
						thead{
							tr{
								th{
									&:nth-of-type(1){	width: 208px;}
									&:nth-of-type(2){	width: 224px;}
									&:nth-of-type(3){	width: 224px;}
								}
							}
						}
						tbody, tfoot{
							tr{
								th, td{
									padding: 14px 15px 14px 20px;
								}
								td{
									text-align: center;
								}
							}
						}
						tbody{
							tr{
								th{
									font-weight: normal;
								}
							}
						}
					}
				}
				.contSubBox03{
					.subBox01{
						margin-top: -12px;
						margin-bottom: 50px;
						& > p{
							margin-bottom: 16px;
						}
					}
					.titleTypeContact{
						.listTypeContact{
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageEstablishment.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/establishment/lower_mainimage_sp_03.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 97px;
				}
				.contSubBox01{
					.boxTypeBorder{
						border-radius: 8px;
						padding-bottom: 16px;
						.colorBox{
							margin-bottom: 20px;
							border-radius: 8px;
						}
						.listTypeBasic{
							.titleTypeSubLeft{
								margin-bottom: 0;
							}
						}
					}
					.subBox01{
						padding-top: 30px;
					}
					.subBox02{
						margin-bottom: 32px;
						padding-top: 33px;
						.titleTypeSub{
							margin-bottom: 24px;
						}
					}
					.contactBox{
						margin-top: 31px;
					}
				}
				.contSubBox02{
					.subBox01{
						margin-top: -9px;
						margin-bottom: 35px;
						& > p{
							margin-bottom: 21px;
						}
					}
					.sp_display{
						margin-bottom: 10px;
						img{
							width: 100%;
							height: auto;
						}
					}
				}
				.contSubBox03{
					.subBox01{
						margin-top: -12px;
						margin-bottom: 33px;
						& > p{
							margin-bottom: 16px;
						}
					}
					.titleTypeContact{
						.listTypeContact{
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}

/*
税務顧問
-------------------------------------*/
#PageAdvisor.pageIndex{
	#MainImg{
		h2{
			&::after{
				content: "Tax adviser";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
	#Main{
		#ContBox01{
			.contSubBox02{
 				.wrapBox{
					.subBox{
						.titleTypeImg{
							text-align: center;
						}
					}
				}
			}
			.contSubBox03{
				.subBox02{
					dl{
						dd{
							&.float{
								.box{
									.tableTypeBasic{
										thead{
											tr{
												th{
													line-height: 1;
													font-size: 14px;
												}
											}
										}
										tbody{
											tr{
												td{
													line-height: 1;
													text-align: left;
													font-size: 14px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.colorBox{
					margin-bottom: 0;
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageAdvisor.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/advisor/lower_pagettl4.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 120px;
				}
				.titleTypeBasicCircle{
					&+ p{
						margin-top: -70px;
						margin-bottom: 68px;
					}
				}
				.contSubBox01{
					.subBox01{
						margin-bottom: 51px;
					}
					.wrapBox{
						margin-bottom: 70px;
						.boxTypeBorder{
							border-radius: 8px;
						}
					}
					.subBox05{
						.tableTypeBasic{
							thead{
								tr{
									th{
										width: 25%;
									}
									&:nth-of-type(1){
										th{
											background-color: #7895c5;
											color: #fff;
										}
									}
									&:nth-of-type(2){
										th{
											padding-top: 19px;
											padding-bottom: 19px;
											line-height: 24px;
											vertical-align: middle;
											&:nth-of-type(2), &:nth-of-type(4){
												text-align: justify;
											}
										}
									}
								}
							}
							tbody{
								tr{
									th, td{
										padding: 16px 20px;
										vertical-align: middle;
									}
									th{
										line-height: 24px;
										text-align: left;
										font-weight: normal;
									}
									td{
										text-align: center;
									}
								}
							}
						}
					}
				}
				.contSubBox02{
					.subBox01{
						margin-top: -22px;
					}
					.wrapBox{
						.subBox{
							float: left;
							margin-bottom: 25px;
							border-radius: 8px;
							padding-top: 40px;
							padding-bottom: 40px;
							width: 420px;
							.titleTypeImg{
								margin-bottom: 20px;
							}
							.colorBox{
								margin-bottom: 20px;
							}
						}
						.subBox02{
							margin-right: 40px;
							.titleTypeImg{
								img{
									width: 158px;
								}
							}
						}
						.subBox03{
							.titleTypeImg{
								img{
									width: 200px;
								}
							}
						}
					}
				}
				.contSubBox03{
					.subBox01{
						margin-top: -21px;
						margin-bottom: 40px;
					}
					.subBox02{
						margin-bottom: 44px;
						dl{
							dd{
								&.float{
									&::after {
										content: "";
										display: block;
										clear: both;
									}
									p{
										float: left;
										width: 680px;
									}
									.box{
										float: right;
										margin-top: -24px;
										width: 173px;
										.tableTypeBasic{
											thead{
												tr{
													th{
														padding-top: 10px;
														padding-bottom: 10px;
													}
												}
											}
											tbody{
												tr{
													td{
														padding: 10px 0 10px 19px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.subBox03{
						margin-bottom: 18px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageAdvisor.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/advisor/lower_mainimage_sp_04.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.contSubBox01{
					padding-bottom: 34px;
					.subBox01{
						margin-top: -6px;
						margin-bottom: 31px;
					}
					.wrapBox{
						margin-bottom: 40px;
						.subBox{
							border-radius: 8px;
							.colorBox{
								border-radius: 8px;
							}
						}
					}
					.subBox05{
						.sp_display{
							margin-bottom: 7px;
							img{
								width: 100%;
								height: auto;
							}
						}
					}
				}
				.contSubBox02{
					padding-bottom: 40px;
					.subBox01{
						margin-top: -5px;
						.titleTypeSub{
							margin-bottom: 10px;
						}
					}
					.wrapBox{
						margin-bottom: -4px;
						.subBox{
							margin-bottom: 30px;
							border-radius: 8px;
							padding-top: 40px;
							padding-bottom: 40px;
							.titleTypeImg{
								margin-bottom: 42px;
							}
							.colorBox{
								border-radius: 8px;
								margin-bottom: 20px;
							}
						}
						.subBox02{
							.titleTypeImg{
								img{
									width: 158px;
								}
							}
						}
						.subBox03{
							.titleTypeImg{
								img{
									width: 200px;
								}
							}
						}
					}
				}
				.contSubBox03{
					.subBox01{
						margin-top: -4px;
						margin-bottom: 28px;
						.titleTypeSub{
							margin-bottom: 45px;
						}
					}
					.subBox02{
						margin-bottom: 30px;
						.titleTypeSub{
							margin-bottom: 40px;
						}
						dl{
							dd{
								&.float{
									p{
									}
									.box{
										margin-top: 19px;
										margin-bottom: 40px;
										.tableTypeBasic{
											thead{
												tr{
													th{
														padding-top: 10px;
														padding-bottom: 10px;
													}
												}
											}
											tbody{
												tr{
													td{
														padding: 10px 0 10px 19px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.subBox03{
						margin-bottom: 33px;
					}
					.colorBox{
						border-radius: 8px;
					}
				}
			}
		}
	}
}

/*
サービス・料金
-------------------------------------*/
#PageService.pageIndex{
	#MainImg{
		h2{
			&::after{
				content: "Servicre / Price";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
	#Main{
		#ContBox01{
			.contSubBox01{
				margin-top: -52px;
				ul{
					li{
						position: relative;
						padding: 0 0 0;
						&:last-of-type{
							margin: 0;
							&::after{
								display: none;
							}
						}
						&::after{
							content: "";
							position: absolute;
							width: 32px;
							height: 32px;
							background-repeat: no-repeat;
							background-position: center center;
							background-image: url("../img/contents/plus_02.png");
							background-size: 32px auto;
						}
						a{
							display: block;
							height: auto;
							text-align: center;
							text-decoration: none;
							span{
								display: inline-block;
								background-repeat: no-repeat;
								background-position: left center;
								background-size: 36px auto;
								letter-spacing: 1;
							}
						}
						&.list01{
							a{
								span{
									background-image: url("../img/contents/mark_a.png");
								}
							}
						}
						&.list02{
							a{
								span{
									background-image: url("../img/contents/mark_b.png");
								}
							}
						}
						&.list03{
							a{
								span{
									background-image: url("../img/contents/mark_c.png");
								}
							}
						}
					}
				}
			}
			.contSubBox03, .contSubBox04, .contSubBox05{
				.titleTypeBasic{
					text-align: center;
					b{
						display: inline-block;
						background-repeat: no-repeat;
						background-position: left center;
						background-size: 36px auto;
					}
				}
				.subBox01{
					.tableTypeBasic{
 						thead{
							tr{
								th{
									span{
										font-size: 14px;
									}
								}
							}
						}
						tbody{
							tr{
								td{
									line-height: 24px;
									text-align: center;
									vertical-align: middle;
								}
							}
						}
					}
				}
			}
			.contSubBox03{
				padding-bottom: 65px;
				.titleTypeBasic{
					b{
						background-image: url("../img/contents/mark_a.png");
					}
				}
				.subBox01{
					.tableTypeBasic{
						caption{
							line-height: 32px;
							font-size: 16px;
						}
					}
				}
			}
			.contSubBox04{
				.titleTypeBasic{
					b{
						background-image: url("../img/contents/mark_b.png");
					}
				}
			}
			.contSubBox05{
				.titleTypeBasic{
					b{
						background-image: url("../img/contents/mark_c.png");
					}
				}
				.subBox01{
					.listTypeBasic{
						dd{
							margin-bottom: 50px;
							.tableTypeBasic{
								margin-top: 32px;
							}
							&.box02{
								.wrapBox{
									h5{
										background-color: #7b6cbc;
										text-align: left;
										font-size: 22px;
										font-weight: bold;
										color: #fff;
										span{
											display: inline-block;
											background-repeat: no-repeat;
											background-position: left center;
											background-image: url("../img/contents/new_01.png");
											letter-spacing: 2px;
										}
									}
									.miniBox01{
										border: 1px solid #7b6cbc;
										p{
											margin-bottom: 0;
											line-height: 28px;
											font-size: 16px;
										}
									}
								}
							}
							&.box06{
								.tableTypeBasic{
									tbody{
										tr{
											td{
												&:nth-of-type(1){
													text-align: left;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.contSubBox06{
				.wrapBox{
					counter-reset: titleNumber;
					.subBox{
						.titleTypeSubLeft{
							&::before{
								counter-increment: titleNumber;
								content: 'CASE.' counter(titleNumber);
								border-radius: 3px;
								background-color: #a4cc65;
								line-height: 1;
								text-align: center;
								font-size: 22px;
								font-weight: bold;
								color: #fff;
							}
						}
						.tableTypeBasic{
							border-color: #fff;
							thead{
								tr{
									th{
										background-color: #7895c5;
										color: #fff;
									}
								}
							}
							tbody{
								tr{
									td{
										border-color: #fff;
										background-color: #e9eff9;
										line-height: 24px;
										text-align: center;
										vertical-align: middle;
										color: #0c458d;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageService.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/service/lower_pagettl5.jpg");
			h2{
				&::after{
					margin-left: -32px;
				}
			}
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 120px;
				}
				.contSubBox01{
					margin-top: -52px;
					ul{
						&::after{
							content: "";
							display: block;
							clear: both;
						}
						li{
							float: left;
							margin-bottom: 88px;
							margin-right: 80px;
							width: 280px;
							height: 80px;
							&::after{
								right: -55px;
								top: 50%;
								margin-top: -16px;
							}
							a{
								padding-top: 13px;
								span{
									padding: 7px 0 3px 47px;
								}
							}
						}
					}
				}
				.contSubBox02{
					margin-bottom: 100px;
					padding-top: 24px;
					padding-bottom: 38px;
					.titleTypeBasic{
						margin-bottom: 16px;
					}
					.listTypeBanner{
						li{
							margin-right: 30px;
							&:last-of-type{
								margin-right: 0;
							}
						}
					}
				}
				.contSubBox03, .contSubBox04, .contSubBox05{
					.titleTypeBasic{
						b{
							padding-left: 47px;
						}
					}
					.boxTypeBorder{
						border-radius: 8px;
					}
					.subBox01{
						margin-top: -7px;
						p{
							&:first-of-type{
								margin-bottom: -1px;
							}
						}
						.tableTypeBasic{
							margin-bottom: 10px;
							thead{
								tr{
									th{
										width: 25%;
									}
								}
							}
							tbody{
								tr{
									td{
										padding: 18px 10px;
									}
								}
							}
						}
					}
					.wrapBox{
						.subBox{
							padding-bottom: 17px;
						}
					}
				}
				.contSubBox03{
					padding-bottom: 65px;
					.subBox01{
						.tableTypeBasic{
							margin-top: 25px;
							&+ .listTypeSmall{
								margin-top: 13px;
							}
							caption{
								margin-bottom: 22px;
							}
						}
					}
					.wrapBox{
						margin-bottom: 53px;
					}
					.subBox05{
						.titleTypeSub{
							margin-bottom: 49px;
						}
						.box01{
							position: relative;
							padding-bottom: 15px;
							background: no-repeat url("../img/contents/service/wall_01.png") left bottom;
							background-size: 879px auto;
							&::before, &::after{
								content: "";
								position: absolute;
								background-repeat: no-repeat;
								background-position: center center;
							}
							&::before{
								top: -24px;
								left: 430px;
								width: 238px;
								height: 25px;
								background-image: url("../img/contents/service/parts_01.png");
								background-size: 238px auto;
							}
							&::after{
								top: -19px;
								left: 437px;
								width: 89px;
								height: 63px;
								background-image: url("../img/contents/service/parts_02.png");
								background-size: 89px auto;
							}
							.tableTypeBasic{
								thead{
									tr{
										th{
											&:nth-of-type(2), &:nth-of-type(4){
												background-repeat: repeat-y;
												background-size: 7px auto;
											}
											&:nth-of-type(2){
												border-right: none;
												background-position: right top;
												background-image: url("../img/contents/service/shadow_l.png");
											}
											&:nth-of-type(3){
												border-right: none;
											}
											&:nth-of-type(4){
												background-position: left top;
												background-image: url("../img/contents/service/shadow_r.png");
											}
											&:nth-of-type(1){
												width: 217px;
											}
											&:nth-of-type(2){
												width: 219px;
											}
											&:nth-of-type(3){
												width: 224px;
											}
										}
										&:nth-of-type(1){
											th{
												background-color: #7895c5;
												color: #fff;
												&:nth-of-type(3){
													padding-top: 4px;
													vertical-align: top;
												}
											}
										}
										&:nth-of-type(2){
											th{
												padding-top: 19px;
												padding-bottom: 19px;
												line-height: 24px;
												vertical-align: middle;
												&:nth-of-type(2), &:nth-of-type(4){
													text-align: justify;
												}
											}
										}
									}
								}
								tbody{
									tr{
										th, td{
											padding: 16px 20px;
											vertical-align: middle;
										}
										th{
											line-height: 24px;
											text-align: left;
										}
										td{
											text-align: center;
											&:nth-of-type(1), &:nth-of-type(3){
												background-repeat: repeat-y;
												background-size: 7px auto;
											}
											&:nth-of-type(1){
												border-right: none;
												background-position: right top;
												background-image: url("../img/contents/service/shadow_l.png");
											}
											&:nth-of-type(2){
												border-right: none;
												background-color: #f9f7ff;
												border-top-color: #cdc5e8;
											}
											&:nth-of-type(3){
												background-position: left top;
												background-image: url("../img/contents/service/shadow_r.png");
											}
										}
									}
								}
							}
						}
					}
				}
				.contSubBox04{
					padding-bottom: 54px;
					.subBox01{
						.tableTypeBasic{
							margin-top: 32px;
							thead{
								tr{
									th{
										padding-right: 0;
										padding-left: 0;
									}
								}
							}
						}
						.listTypeBasic{
							margin-top: 45px;
						}
					}
				}
				.contSubBox05{
					.subBox01{
						.listTypeBasic{
							margin-top: 25px;
							dd{
								margin-bottom: 50px;
								.tableTypeBasic{
									margin-top: 32px;
								}
								&.box01{
									.tableTypeBasic{
										thead{
											tr{
												th{
													&:nth-of-type(2), &:nth-of-type(3){
														width: 37.5%;
													}
												}
											}
										}
									}
								}
								&.box02{
									.tableTypeBasic{
										tbody{
											tr{
												&:nth-of-type(2){
													td{
														border-bottom-right-radius: 0;
														border-right: 1px solid #aab5d4;
													}
												}
											}
										}
									}
									.wrapBox{
										display: table;
										margin-top: 39px;
										h5, .miniBox01{
											display: table-cell;
											vertical-align: middle;
										}
										h5{
											border-top-left-radius: 8px;
											border-bottom-left-radius: 8px;
											padding: 14px 0 14px 16px;
											width: 220px;
											text-align: left;
											span{
												padding: 10px 0 10px 65px;
												background-size: 50px auto;
												letter-spacing: 2px;
											}
										}
										.miniBox01{
											border-top-right-radius: 8px;
											border-bottom-right-radius: 8px;
											padding: 12px 28px;
										}
									}
								}
								&.box04{
									.tableTypeBasic{
										tbody{
											tr{
												td{
													&:nth-of-type(1){
														padding: 18px 10px;
														line-height: 24px;
													}
												}
											}
										}
									}
								}
								&.box05{
									.tableTypeBasic{
										tbody{
											tr{
												td{
													ul{
														li{
															line-height: 32px;
															text-align: center;
															font-size: 16px;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.contSubBox06{
					padding-bottom: 40px;
					.wrapBox{
						.subBox{
							padding-top: 41px;
							padding-bottom: 40px;
							.titleTypeSubLeft{
								margin-bottom: 21px;
								&::before{
									display: inline-block;
									margin-right: 20px;
									padding: 6px 15px;
								}
							}
							.listTypeCircle{
								margin-bottom: 21px;
							}
							dl.listTypeBasic{
								dd:not(:last-of-type){
									margin-bottom: 29px;
								}
							}
							.tableTypeBasic{
								margin-top: 22px;
								thead{
									tr{
										th{
											width: 155px;
											&:last-of-type{
												width: auto;
											}
										}
									}
								}
								tbody{
									tr{
										td{
											padding: 20px 10px;
										}
										&:last-of-type{
											td:first-of-type{
												border-bottom-left-radius: 8px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageService.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/service/lower_mainimage_sp_05.jpg");
			background-position: left bottom -1.5%, left top;
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.contSubBox01{
					margin-top: -9px;
					margin-bottom: 55px;
					.titleTypeBasic{
						margin-right: -15px;
						margin-left: -15px;
						line-height: 60px;
						font-size: 32px;
					}
					ul{
						li{
							margin-bottom: 72px;
							&::after{
								right: 50%;
								bottom: -52px;
								margin-right: -16px;
							}
							a{
								padding-top: 16px;
								padding-bottom: 16px;
								span{
									padding: 5px 0 5px 47px;
								}
							}
						}
					}
				}
				.contSubBox02{
					margin-bottom: 80px;
					padding-bottom: 29px;
					.listTypeBanner{
						padding: 0 27px;
						li{
							text-align: center;
							&.list01{
								margin-bottom: -2px;
							}
							&.list02{
								margin-bottom: -3px;
							}
						}
					}
				}
				.contSubBox03, .contSubBox04, .contSubBox05{
					padding-top: 35px;
					.titleTypeBasic{
						b{
							padding-left: 47px;
						}
					}
					.sp_display{
						margin-bottom: 6px;
						img{
							width: 100%;
							height: auto;
						}
					}
					.subBox01{
						margin-top: -4px;
						p{
							&:first-of-type{
								margin-bottom: -1px;
							}
						}
					}
					.wrapBox{
						.subBox{
							border-radius: 8px;
							padding-bottom: 17px;
							.titleTypeSub{
								margin-bottom: 38px;
							}
							.colorBox{
								border-radius: 8px;
								padding-bottom: 16px;
							}
							.scrollBox{
								img{
									width: 796px;
								}
							}
						}
					}
				}
				.contSubBox03{
					padding-bottom: 41px;
					.subBox01{
						.titleTypeSubLeft{
							margin-top: 23px;
							margin-bottom: 20px;
							line-height: 32px;
							font-size: 16px;
						}
					}
					.wrapBox{
						margin-bottom: 45px;
						.subBox{
							padding-top: 34px;
						}
					}
					.subBox05{
						.titleTypeSub{
							margin-bottom: 18px;
						}
					}
				}
				.contSubBox04{
					padding-bottom: 24px;
					.subBox01{
						.sp_display{
							margin-top: 22px;
						}
						.listTypeBasic{
							margin-top: 21px;
						}
					}
				}
				.contSubBox05{
					padding-bottom: 15px;
					.subBox01{
						.listTypeBasic{
							margin-top: 25px;
							dd{
								margin-bottom: 35px;
								figure{
									margin-top: 19px;
									margin-bottom: 7px;
								}
								.tableTypeBasic{
									margin-top: 22px;
									margin-bottom: 10px;
									thead{
										tr{
											th{
												width: 50%;
												padding: 11px 10px;
											}
										}
									}
									tbody{
										tr{
											td{
												padding: 10px;
												font-size: 14px;
											}
										}
									}
								}
								&.box02{
									margin-bottom: 42px;
									.wrapBox{
										margin-top: 35px;
										h5{
											border-top-right-radius: 8px;
											border-top-left-radius: 8px;
											padding: 13px 0;
											text-align: center;
											span{
												padding: 10px 0 10px 64px;
												background-size: 48px auto;
												letter-spacing: 2px;
											}
										}
										.miniBox01{
											border-bottom-right-radius: 8px;
											border-bottom-left-radius: 8px;
											padding: 13px 19px;
										}
									}
								}
								&.box03{
									margin-bottom: 43px;
								}
								&.box05{
									margin-bottom: 42px;
									.tableTypeBasic{
										tbody{
											tr{
												td{
													&:nth-of-type(1){
														padding: 14px 10px;
														ul{
															li{
																margin-bottom: 12px;
																line-height: 24px;
																text-align: center;
																font-size: 14px;
																&:last-of-type{
																	margin-bottom: 0;
																}
															}
														}
													}
												}
											}
										}
									}
								}
								&.box06{
									.tableTypeBasic{
										tbody{
											tr{
												td{
													&:nth-of-type(1){
														padding: 13px 15px;
														text-align: left;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.contSubBox06{
					padding-bottom: 20px;
					.wrapBox{
						.subBox{
							padding-top: 40px;
							padding-bottom: 40px;
							.titleTypeSubLeft{
								margin-bottom: 9px;
								text-align: center;
								&::before{
									display: block;
									margin: 0 auto 14px;
									padding: 7px 0;
									width: 126px;
								}
							}
							.listTypeCircle{
								margin-bottom: 21px;
							}
							dl.listTypeBasic{
								dd:not(:last-of-type){
									margin-bottom: 29px;
								}
							}
							.tableTypeBasic{
								display: block;
								margin-top: 22px;
								&::after {
									content: "";
									display: block;
									clear: both;
								}
								thead, tbody, tr, th, td{
									display: block;
								}
								thead, tbody{
									float: left;
								}
								thead{
									width: 100px;
									tr{
										th{
											border: none;
											border-bottom: 1px solid #aab5d4;
											padding: 15px 15px 0;
											line-height: 24px;
											text-align: left;
											font-size: 16px;
											&:nth-of-type(1){
												height: 79px;
											}
											&:nth-of-type(2){
												padding-top: 11px;
												height: 46px;
											}
											&:nth-of-type(3){
												padding-top: 13px;
												height: 71px;
											}
											&:nth-of-type(4){
												padding-top: 11px;
												height: 46px;
											}
											&:nth-of-type(5){
												border-top-right-radius: 0;
												border-bottom-left-radius: 8px;
												border-bottom: none;
											}
										}
									}
								}
								tbody{
									width: calc(100% - 100px);
									tr{
										td{
											border: none;
											border-bottom: 1px solid #fff;
											padding: 12px 0 0;
											line-height: 24px;
											font-size: 14px;
											&:nth-of-type(1){
												border-top-right-radius: 8px;
												padding-top: 27px;
												height: 79px;
											}
											&:nth-of-type(2){
												height: 46px;
											}
											&:nth-of-type(3){
												padding-top: 22px;
												height: 71px;
											}
											&:nth-of-type(4){
												height: 46px;
											}
											&:nth-of-type(5){
												border-bottom: none;
												padding-top: 8px;
											}
										}
									}
								}
							}
						}
						.subBox01{
							.tableTypeBasic{
								thead{
									tr{
										th{
											&:nth-of-type(5){
												padding-top: 21px;
												height: 65px;
											}
										}
									}
								}
								tbody{
									tr{
										td{
											&:nth-of-type(5){
												height: 65px;
											}
										}
									}
								}
							}
						}
						.subBox02{
							.tableTypeBasic01{
								thead{
									tr{
										th{
											&:nth-of-type(5){
												padding-top: 32px;
												height: 90px;
											}
										}
									}
								}
								tbody{
									tr{
										td{
											&:nth-of-type(5){
												height: 90px;
											}
										}
									}
								}
							}
							.tableTypeBasic02{
								thead{
									tr{
										th{
											&:nth-of-type(5){
												padding-top: 10px;
												height: 41px;
											}
										}
									}
								}
								tbody{
									tr{
										td{
											&:nth-of-type(5){
												height: 41px;
											}
										}
									}
								}
							}
						}
						.subBox03{
							.tableTypeBasic{
								thead{
									tr{
										th{
											&:nth-of-type(5){
												padding-top: 21px;
												height: 65px;
											}
										}
									}
								}
								tbody{
									tr{
										td{
											&:nth-of-type(5){
												height: 65px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 320px) {
	#PageService.pageIndex{
		#Main{
			#ContBox01{
				.contSubBox06{
					.wrapBox{
						.subBox02{
							.tableTypeBasic01{
								thead, tbody{
									tr{
										th, td{
											&:nth-of-type(5){
												height: 160px;
											}
										}
									}
								}
							}
							.tableTypeBasic02{
								thead, tbody{
									tr{
										th, td{
											&:nth-of-type(4){
												height: 67px;
											}
											&:nth-of-type(5){
												height: 67px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
/*
お問い合わせ
-------------------------------------*/
#PageInquiry{
	#MainImg{
		background-image: url("../img/contents/wall_02.png"), url("../img/contents/contact/lower_pagettl7.jpg");
		h2{
			&::after{
				content: "Contact";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
	#Main{
		#ContBox02{
			.contSubBox01{
				.tableTypeForm{
					tbody{
						tr{
							&.text11{
								td{
									dl{
										dt{
											color: #888;
										}
									}
								}
							}
							&.text12{
								td{
									ul{
										li{
											dl{
												display: table;
												width: 100%;
												dt, dd{
													display: table-cell;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageInquiry{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/contact/lower_pagettl7.jpg");
		}
		#Main{
			#ContBox02{
				.contSubBox01{
					.tableTypeForm{
						tbody{
							tr{
								&.text11{
									td{
										dl{
											&::after {
												content: "";
												display: block;
												clear: both;
											}
											dt{
												&.dt01{
													float: left;
													width: 96px;
												}
												&.dt02{
													clear: both;
													padding-top: 25px;
													span{
														padding-left: 28px;
														font-size: 14px;
													}
												}
											}
											dd{
												&.dd01{
													float: right;
													margin-top: -5px;
													margin-bottom: 9px;
													width: 524px;
												}
												&.dd02{
													padding-left: 96px;
												}
											}
										}
									}
								}
								&.text12{
									td{
										p{
											margin-bottom: 9px;
										}
										ul{
											&::after {
												content: "";
												display: block;
												clear: both;
											}
											li{
												float: left;
												margin-bottom: 20px;
												&:nth-of-type(2n+1){
													margin-right: 30px;
													width: 280px;
													dl{
														dt{
															width: 103px;
														}
													}
												}
												&:nth-of-type(2n){
													width: 310px;
													dl{
														dt{
															width: 138px;
														}
													}
												}
												&:nth-last-of-type(2), &:last-of-type{
													margin-bottom: 0;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageInquiry{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/contact/lower_mainimage_sp_07.jpg");
		}
		#Main{
			#ContBox02{
				.contSubBox01{
					.tableTypeForm{
						tbody{
							tr{
								&.text11{
									td{
										dl{
											dt{
												&.dt02{
													margin-bottom: -1px;
													&::after {
														content: "";
														display: block;
														clear: both;
													}
													b, span{
														display: block;
													}
													b{
														float: left;
														width: 78px;
													}
													span{
														font-size: 14px;
														padding-left: 78px;
													}
												}
											}
											dd{
												&.dd02{
													ul{
														li{
															margin-bottom: 4px;
															span{
																display: block;
																clear: both;
															}
														}
													}
												}
											}
										}
									}
								}
								&.text12{
									td{
										p{
											margin-bottom: 9px;
										}
										ul{
											&::after {
												content: "";
												display: block;
												clear: both;
											}
											li{
												margin-bottom: 20px;
												&:last-of-type{
													margin-bottom: 0;
												}
												dl{
													dd{
														width: 166px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
お問い合わせ
-------------------------------------*/
#PageInquiry.pageIndex{
	#Container{
		background-color: #fff;
	}
	#Main{
		#ContBox01{
			.contSubBox01{
				.titleTypeBasicCircle{
					padding-top: 0;
					&::before{
						display: none;
					}
				}
				.contactBox{
					text-align: left;
					.listTypeContact{
						background-repeat: repeat-x;
						background-position: left bottom;
						background-image: url("../img/contents/dot_02.gif");
						background-size: auto 1px;
						.telBox{
							a{
								&> span{
									padding: 0;
									background-image: none;
									font-size: 0;
									span{
										font-weight: bold;
										font-size: 22px;
									}
									b{
										background-repeat: no-repeat;
										background-position: left top;
										background-image: url("../img/contents/00_iconSodan.png");
										font-size: 36px;
									}
									em{
										border: 1px solid #fff;
										text-align: center;
										font-size: 14px;
									}
								}
							}
						}
					}
				}
				.toolBox{
					ul{
						font-size: 0;
						li{
							display: inline-block;
							background-position: left top;
						}
					}
					b{
						font-size: 20px;
					}
				}
			}
		}
		#ContBox02{
			.contSubBox01{
				.tableTypeForm{
					tbody{
						tr{
							&.text11{
								td{
									dl{
										dd{
											&.dd02{
												ul{
													li{
														line-height: 44px;
														&:last-of-type{
															margin-bottom: 0;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageInquiry.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_01.png"), url("../img/contents/contact/lower_pagettl7.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 119px;
				}
				.contSubBox01{
					.titleTypeBasicCircle{
						margin-top: -17px;
						margin-bottom: 44px;
					}
					.contactBox{
						margin-bottom: 80px;
						padding: 14px 60px 16px;
						text-align: left;
						.listTypeContact{
							margin: 0 0 20px;
							padding: 10px 0 31px;
							.telBox{
								width: auto;
								a{
									&> span{
										span, b, em{
											display: inline-block;
											vertical-align: middle;
										}
										span{
											padding: 10px 0 0;
											width: 230px;
											text-align: left;
											letter-spacing: 7px;
										}
										b{
											position: relative;
											top: 5px;
											padding: 8px 0 10px 54px;
											width: 354px;
											background-size: 45px auto;
											letter-spacing: .01em;
										}
										em{
											border-radius: 3px;
											padding: 8px 0 0;
											width: 296px;
											height: 30px;
											line-height: 1;
											letter-spacing: 0.05em;
										}
									}
								}
							}
						}
						p{
							&> span{
								padding-left: 0;
							}
						}
					}
					.toolBox{
						ul{
							margin-bottom: 30px;
							padding-left: 60px;
							li{
								padding-top: 14px;
								padding-left: 80px;
								min-height: 60px;
								background-size: 60px auto;
								font-size: 24px;
								&.list01{
									width: 198px;
								}
								&.list02{
									width: 250px;
								}
								&.list03{
									width: 184px;
								}
								&.list04{
									width: 260px;
								}
							}
						}
						b{
							margin: 0 auto;
							border-radius: 8px;
							padding-top: 11px;
							width: 880px;
							height: 40px;
							letter-spacing: 6px;
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-top: 160px;
					padding-bottom: 120px;
				}
				.contSubBox01{
					padding-bottom: 70px;
					.titleTypeBasic{
						margin-bottom: 46px;
					}
					.tableTypeForm{
						tbody{
							tr{
								td{
									padding-top: 20px;
									padding-bottom: 20px;
									.listTypeCheckBox{
										margin-bottom: 20px;
									}
									input{
										&#text4, &#text5{
											width: 365px;
										}
										&#text6, &#text7{
											width: 505px;
										}
										&#text8{
											width: 285px;
										}
										&#text9{
											width: 185px;
										}
									}
								}
								&.text11{
									td{
										dl{
											dd{
												&.dd02{
													ul{
														li{
															margin-bottom: 20px;
															input[type="text"]{
																width: 105px;
																&:nth-of-type(1){
																	margin-right: 6px;
																	margin-left: 36px;
																}
																&:nth-of-type(2){
																	margin-right: 6px;
																	margin-left: 6px;
																}
																&:nth-of-type(3){
																	margin-right: 6px;
																	margin-left: 6px;
																}
															}
														}
													}
												}
											}
										}
									}
								}
								&.text12{
									td{
										ul{
											li{
												input[type="text"]{
													width: 177px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.contSubBox02{
					padding-bottom: 57px;
					.titleTypeBasic{
						margin-bottom: 27px;
					}
					.subBox{
						margin-bottom: 29px;
					}
					.subBox01{
						margin-top: 29px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageInquiry.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_01_sp.png"), url("../img/contents/contact/lower_mainimage_sp_07.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 80px;
				}
				.contSubBox01{
					.titleTypeBasicCircle{
						margin-top: -17px;
						margin-bottom: 26px;
					}
					.contactBox{
						margin-bottom: 40px;
						padding: 40px 25px 30px;
						text-align: left;
						.listTypeContact{
							margin: 0 0 24px;
							padding-bottom: 35px;
							.telBox{
								float: none;
								padding: 0;
								width: auto;
								a{
									text-align: center;
									&> span{
										width: 100%;
										span, b, em{
											display: block;
										}
										span{
											margin-bottom: 11px;
											padding: 0;
											text-align: center;
											letter-spacing: 6px;
										}
										b{
											display: inline-block;
											margin-bottom: 12px;
											padding: 8px 0 8px 45px;
											background-position: left center;
											background-size: 38px auto;
											letter-spacing: 0;
											font-size: 31px;
										}
										em{
											border-radius: 3px;
											padding: 0;
											line-height: 29px;
											letter-spacing: 0.05em;
										}
									}
								}
							}
						}
						p{
							margin-bottom: 0;
							line-height: 28px;
							text-align: justify;
							letter-spacing: .05em;
						}
					}
					.toolBox{
						ul{
							margin-bottom: 22px;
							li{
								margin-bottom: 19px;
								padding-top: 12px;
								padding-left: 57px;
								min-height: 45px;
								background-size: 45px auto;
								line-height: 24px;
								vertical-align: middle;
								font-size: 20px;
								&:nth-of-type(2n+1){
									width: 42%;
								}
								&:nth-of-type(2n){
									width: 58%;
								}
							}
						}
						b{
							border-radius: 8px;
							padding: 9px 5px;
							letter-spacing: 5px;
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-top: 83px;
					padding-bottom: 100px;
				}
				.contSubBox01{
					padding-bottom: 50px;
					.titleTypeBasic{
						margin-bottom: 32px;
					}
					.tableTypeForm{
						tbody{
							tr{
								td{
									.listTypeCheckBox{
										margin-bottom: 14px;
									}
								}
								&.text11{
									td{
										dl{
											dd{
												&.dd01{
													.listTypeRadio{
														font-size: 0;
														li{
															display: inline-block;
															width: 49%;
															vertical-align: top;
															&:nth-of-type(2n+1){
																padding-right: 10px;
															}
														}
													}
												}
												&.dd02{
													ul{
														li{
															input[type="text"]{
																width: 20%;
																&:nth-of-type(1){
																	margin-right: 11px;
																}
																&:nth-of-type(2){
																	margin-right: 9px;
																	margin-left: 7px;
																}
																&:nth-of-type(3){
																	margin-right: 10px;
																	margin-left: 7px;
																}
															}
														}
													}
												}
											}
										}
									}
								}
								&.text12{
									td{
										ul{
											li{
												margin-bottom: 20px;
												&:last-of-type{
													margin-bottom: 0;
												}
												input[type="text"]{
													width: 100%;
												}
												dl{
													dt{
														width: 123px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.contSubBox02{
					padding-bottom: 12px;
					.titleTypeBasic{
						margin-bottom: 21px;
					}
					.subBox{
						margin-bottom: 29px;
					}
					.subBox01{
						margin-top: 29px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 320px) {
	#PageInquiry.pageIndex{
		#Main{
			#ContBox01{
				.contSubBox01{
					.contactBox{
						.listTypeContact{
							.telBox{
								a{
									&> span{
										b{
											font-size: 25px;
										}
										em{
											font-size: 12px;
										}
									}
								}
							}
						}
						p{
							margin-bottom: 0;
							line-height: 28px;
							text-align: justify;
							letter-spacing: .05em;
						}
					}
					.toolBox{
						ul{
							margin-bottom: 22px;
							li{
								margin-bottom: 19px;
								padding-top: 12px;
								padding-left: 57px;
								min-height: 45px;
								background-size: 45px auto;
								line-height: 24px;
								vertical-align: middle;
								font-size: 20px;
								&:nth-of-type(2n+1){
									width: 42%;
								}
								&:nth-of-type(2n){
									width: 58%;
								}
							}
						}
						b{
							border-radius: 8px;
							padding: 9px 5px;
							letter-spacing: 5px;
						}
					}
				}
			}
		}
	}
}

/*
確認画面
-------------------------------------*/
#PageInquiry.pageConfirm{
	#Main{
		padding-top: 0;
		#ContBox02{
			.contSubBox01{
				.titleTypeBasic{
					&+ p{
						text-align: center;
					}
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageInquiry.pageConfirm{
		#Main{
			margin-top: -3px;
			padding-top: 0;
			#ContBox02{
				.innerBasic{
					padding-top: 83px;
					padding-bottom: 120px;
				}
				.contSubBox01{
					padding-bottom: 80px;
					.titleTypeBasic{
						margin-bottom: 27px;
						&+ p{
							margin-bottom: 40px;
						}
					}
					.tableTypeForm{
						tbody{
							tr{
								td{
									p{
										margin-top: -13px;
									}
								}
								&.text11{
									td{
										dl{
											dt{
												&.dt02{
													padding-top: 0;
												}
											}
											dd{
												&.dd01{
													margin-bottom: 3px;
													padding-top: 5px;
												}
												&.dd02{
													line-height: 35px;
												}
											}
										}
									}
								}
								&.text12{
									ul{
										li{
											margin-bottom: 4px;
											&:nth-of-type(2n+1){
												width: 288px;
											}
											&:nth-of-type(2n){
												width: 300px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageInquiry.pageConfirm{
		#Main{
			margin-top: -3px;
			padding-top: 0;
			#ContBox02{
				.innerBasic{
					padding-top: 59px;
					padding-bottom: 100px;
				}
				.contSubBox01{
					padding-bottom: 49px;
					.titleTypeBasic{
						margin-bottom: 23px;
						&+ p{
							margin-bottom: 29px;
						}
					}
					.tableTypeForm{
						tbody{
							tr{
								th{
									padding-bottom: 18px;
								}
								td{
									padding-bottom: 23px;
									p{
										margin-top: -3px;
										margin-bottom: 10px;
									}
								}
								&.text11{
									td{
										dl{
											&::after {
												content: "";
												display: block;
												clear: both;
											}
											dt{
												&.dt01{
													float: left;
													margin-bottom: 17px;
													width: 77px;
												}
												&.dt02{
													clear: both;
													margin-bottom: 4px;
													padding-top: 0;
													
												}
											}
											dd{
												&.dd01{
													margin-bottom: 3px;
													padding-left: 77px;
												}
												&.dd02{
													padding-left: 77px;
													line-height: 35px;
												}
											}
										}
									}
								}
								&.text12{
									ul{
										li{
											margin-bottom: 10px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
送信完了画面
-------------------------------------*/
#PageInquiry.pageThanks{
	#Main{
		padding-top: 0;
		#ContBox01{
			.contSubBox01{
				.titleTypeBasic{
					&+ p{
						text-align: center;
					}
				}
				.button{
					border-top: 1px solid #e6e6e6;
				}
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageInquiry.pageThanks{
		#Main{
			#ContBox01{
				.innerBasic{
					padding-top: 83px;
					padding-bottom: 120px;
				}
				.contSubBox01{
					padding-bottom: 80px;
					.titleTypeBasic{
						margin-bottom: 27px;
						&+ p{
							margin-bottom: 51px;
						}
					}
					.button{
						padding-top: 60px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageInquiry.pageThanks{
		#Main{
			#ContBox01{
				.innerBasic{
					padding-top: 60px;
					padding-bottom: 100px;
				}
				.contSubBox01{
					padding-bottom: 50px;
					.titleTypeBasic{
						margin-bottom: 21px;
						&+ p{
							margin-bottom: 20px;
						}
					}
					.button{
						padding-top: 42px;
					}
				}
			}
		}
	}
}

/*
事務所案内
-------------------------------------*/
#PageOffice.pageIndex{
	#MainImg{
		h2{
			&::after{
				content: "Office guidance";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
	#Main{
		#ContBox01{
			.contSubBox01{
				.colorBox{
					background-color: #a4cc65;
					color: #fff;
					h4{
						font-size: 32px;
						font-weight: bold;
					}
					.box{
						p{
							margin-bottom: 0;
							font-size: 22px;
							font-weight: bold;
							span{
								display: block;
								border-radius: 3px;
								background-color: #fff;
								text-align: center;
								font-size: 16px;
								color: #97bf57;
							}
						}
					}
				}
				.listTypeMath{
					li{
						text-align: justify;
					}
				}
			}
			.contSubBox02, .contSubBox03{
				.titleTypeSubLeft{
					span{
						font-size: 16px;
						font-weight: normal;
					}
				}
				.tableTypeBasic{
					tbody{
						tr{
							th, td{
								line-height: 24px;
							}
							th{
								background-color: #e9eff9;
								text-align: left;
								color: #0c458d;
							}
							td{
								p{
									line-height: 24px;
								}
							}
 						}
					}
				}
			}
			.contSubBox02{
				.wrapBox{
					.imgBox{
						img{
							width: 100%;
							height: auto;
						}
					}
					.txtBox{
						dl{
							.titleTypeSubLeft{
								span{
									font-weight: normal;
									font-size: 16px;
								}
							}
							dd{
								line-height: 28px;
								font-size: 14px;
								color: #888;
							}
						}
					}
				}
			}
			.contSubBox03{
				.tableTypeBasic01{
					tbody{
						tr{
							td{
								.listTypeBanner{
									li{
										display: block; 
										margin-bottom: 10px;
										&:last-of-type{
											margin-bottom: 0;
										}
									}
								}
								.cafeImg{
									min-height: 74px;
									background-repeat: no-repeat;
									background-position: left top;
									background-image: url("../img/contents/office/06_gsl.png");
									background-size: 60px auto;
									vertical-align: middle;
								}
							}
						}
					}
				}
			}
			.contSubBox04{
                padding-bottom: 71px;
                .subBox{
                    &.subBox01{
                        #Map{
                            &+ p{
                                margin-bottom: 0;
                                span{
                                    background-repeat: no-repeat;
                                    background-position: left top;
                                    background-image: url("../img/contents/06_map.png");
                                    background-size: 23px auto;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    &.subBox02{
                        .imgBox{
                            margin: 0 auto;
                            img{
                                width: 100%;
                                height: auto;
                            }
                        }

                    }
                }
			}
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageOffice.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/office/lower_pagettl6.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 120px;
				}
				.contSubBox01{
					padding-bottom: 56px;
					display: table;
					.colorBox{
						margin-top: -4px;
						margin-bottom: 32px;
						border-radius: 4px;
						padding: 28px 45px 20px 64px;
						h4, .box01{
							display: table-cell;
							vertical-align: middle;
							text-align: left;
						}
						h4{
							width: 231px;
							background-repeat: repeat-y;
							background-position: right top;
							background-image: url("../img/contents/dot_03.gif");
						}
						.box{
							padding-left: 30px;
							p{
								line-height: 36px;
								span{
									margin-bottom: 16px;
									border-radius: 3px;
									padding: 10px 0;
									width: 365px;
									line-height: 1;
									letter-spacing: 0;
 								}
							}
						}
					}
					.listTypeMath{
						li{
							margin-bottom: 15px;
						}
					}
				}
				.contSubBox02, .contSubBox03{
					.titleTypeSubLeft{
						margin-bottom: 9px;
					}
					.tableTypeBasic{
						.titleTypeSubLeft{
							margin-bottom: 9px;
						}
						tbody{
							tr{
								th, td{
									padding-top: 17px;
									padding-bottom: 17px;
									vertical-align: middle;
									font-size: 16px;
								}
								th{
									padding-right: 18px;
									padding-left: 18px;
									text-align: left;
								}
								td{
									padding-right: 20px;
									padding-left: 20px;
									p{
										vertical-align: middle;
										font-size: 16px;
									}
								}
								&:first-of-type{
									th, td{
										border-top: none;
									}
									th{
										border-top-left-radius: 8px;
									}
								}
							}
						}
					}
				}
				.contSubBox02{
					.wrapBox{
						margin-bottom: 27px;
						padding-top: 6px;
						.imgBox{
							float: left;
							width: 226px;
						}
						.txtBox{
							float: right;
							margin-top: -8px;
							width: 615px;
							dl{
								margin-bottom: 18px;
								.titleTypeSubLeft{
									margin-bottom: 4px;
 								}
							}
						}
					}
					.tableTypeBasic01{
						margin-bottom: 54px;
						tbody{
							tr{
								th{
									padding-right: 0;
									padding-left: 0;
									width: 101px;
									text-align: center;
								}
								td.cafeImg{
									height: 233px;
									background-repeat: no-repeat;
									background-position: right 19px top 19px;
									background-image: url("../img/contents/office/06_keireki.png");
									background-size: 270px auto;
								}
							}
						}
					}
					.tableTypeBasic02{
						tbody{
							tr{
								th{
									width: 139px;
								}
							}
						}
					}
				}
				.contSubBox03{
					.tableTypeBasic01{
						tbody{
							tr{
								th{
									width: 156px;
								}
								td{
									.listTypeBanner{
										margin-top: 13px;
										margin-bottom: 5px;
										li{
											&.list01{
												margin-bottom: -15px;
											}
											&.list02{
												margin-bottom: -14px;
											}
										}
									}
									.cafeImg{
										margin-bottom: 7px;
										padding: 27px 0 0 79px;
										min-height: 74px;
										vertical-align: middle;
									}
								}
							}
						}
					}
				}
				.contSubBox04{
					padding-bottom: 71px;
                    .subBox{
                        &.subBox01{
                            border-bottom: 1px solid #e6e6e6;
                            padding-bottom: 49px;
                            #Map{
                                margin-bottom: 8px;
                                iframe{
                                    width: 880px;
                                    height: 364px;
                                }
                                &+ p{
                                    span{
                                        padding: 5px 0 5px 29px;
                                    }
                                }
                            }
                        }
                        &.subBox02{
                            .imgBox{
                                width: 446px;
                            }

                        }
                    }
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageOffice.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/office/lower_mainimage_sp_06.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.contSubBox01{
					padding-bottom: 26px;
					.colorBox{
						margin-top: 1px;
						margin-bottom: 34px;
						border-radius: 4px;
						padding: 35px 21px 24px 21px;
						h4, .box01{
							text-align: left;
						}
						h4{
							margin-bottom: 30px;
							padding-bottom: 19px;
							background-repeat: repeat-x;
							background-position: left bottom;
							background-image: url("../img/contents/dot_02.gif");
							background-size: auto 1px;
							text-align: center;
						}
						.box{
							p{
								line-height: 38px;
								span{
									margin-bottom: 16px;
									border-radius: 3px;
									padding: 15px 0;
									line-height: 26px;
									letter-spacing: 0;
 								}
							}
						}
					}
					.listTypeMath{
						li{
							margin-bottom: 15px;
						}
					}
				}
				.contSubBox02, .contSubBox03{
					.titleTypeSubLeft{
						margin-bottom: 9px;
					}
					.tableTypeBasic{
						border: none;
						display: block;
						tr, th, td, tbody, caption{
							display: block;
						}
						.titleTypeSubLeft{
							margin-bottom: 9px;
						}
						tbody{
							tr{
								th, td{
									border-right: 1px solid #aab5d4;
									border-left: 1px solid #aab5d4;
									font-size: 16px;
								}
								th{
									border-bottom: none;
									padding-top: 16px;
									padding-bottom: 16px;
									text-align: left;
								}
								td{
									border-top: none;
									padding: 16px;
									font-size: 14px;
									p{
										font-size: 14px;
									}
								}
								&:first-of-type{
									th{
										border-top: 1px solid #aab5d4;
										border-top-right-radius: 8px;
										border-top-left-radius: 8px;
									}
								}
								&:last-of-type{
									th{
										border-bottom-right-radius: 0;
										border-bottom-left-radius: 0;
									}
									td{
										border-bottom: 1px solid #aab5d4;
										border-bottom-right-radius: 6px;
										border-bottom-left-radius: 6px;
									}
								}
							}
						}
					}
				}
				.contSubBox02{
					.wrapBox{
						margin-bottom: 22px;
						.imgBox{
							margin: 0 auto 33px;
							width: 226px;
						}
						.txtBox{
							margin-top: -8px;
							dl{
								margin-bottom: 18px;
								.titleTypeSubLeft{
									margin-bottom: 4px;
 								}
							}
						}
					}
					.tableTypeBasic01{
						margin-bottom: 32px;
						tbody{
							tr{
								th{
								}
								td.cafeImg{
									img{
										margin-top: 12px;
										width: 100%;
										height: auto;
									}
								}
							}
						}
					}
				}
				.contSubBox03{
					.tableTypeBasic01{
						tbody{
							tr{
								td{
									.listTypeBanner{
										margin-top: 12px;
										margin-bottom: 4px;
										.list01{
											margin-bottom: -12px;
											img{
												max-width: 245px;
											}
										}
										.list02{
											margin-bottom: -15px;
											img{
												max-width: 234px;
											}
										}
										.list03 img{
											max-width: 234px;
										}
									}
									.cafeImg{
										margin-bottom: 7px;
										padding: 0 0 0 79px;
										min-height: 79px;
									}
								}
							}
						}
					}
				}
				.contSubBox04{
					padding-bottom: 59px;
                    .subBox{
                        &.subBox01{
                            #Map{
                                margin-bottom: 8px;
                                iframe{
                                    width: 100%;
                                    height: 285px;
                                }
                                &+ p{
                                    span{
                                        padding: 8px 0 5px 34px;
                                    }
                                }
                            }
                        }
                        &.subBox02{
                        }
                    }
				}
			}
		}
	}
}

/*
よくある質問
-------------------------------------*/
#PageFaq.pageIndex{
	#MainImg{
		h2{
			&::after{
				content: "Question";
			}
		}
	}
	#Container{
		background-color: #e9eff9;
	}
}

@media print, screen and (min-width: 768px) {
	#PageFaq.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02.png"), url("../img/contents/faq/lower_pagettl8.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 120px;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFaq.pageIndex{
		#MainImg{
			background-image: url("../img/contents/wall_02_sp.png"), url("../img/contents/faq/lower_mainimage_sp_08.jpg");
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 100px;
				}
				.contSubBox{
					.titleTypeBasic{
						margin-bottom: 54px;
					}
					.listTypeFaq{
						dt{
							text-align: justify;
						}
						dd{
							.listTypeCircle{
								li{
									text-align: justify;
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
トピックス
-------------------------------------*/
#PageTopics{
	#MainImg{
		h2{
			&::after{
				content: "Topics";
			}
		}
	}
	#Container{
		padding-bottom: 115px;
	}
	#Main{
		.titleTypeBasic{
			text-align: left;
		}
	}
}

@media print, screen and (min-width: 768px) {
	#PageTopics{
		#MainImg{
			background-image: url("../img/contents/wall_01.png"), url("../img/contents/topoics/lower_pagettl9.jpg");
		}
		#Main{
			.titleTypeBasic{
				margin-top: 6px;
				margin-bottom: 50px;
				font-size: 36px;
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics{
		#MainImg{
			background-image: url("../img/contents/wall_01_sp.png"), url("../img/contents/topoics/lower_mainimage_sp_09.jpg");
		}
		#Main{
			.titleTypeBasic{
				margin-top: -11px;
				margin-bottom: 38px;
			}
		}
	}
}

/*
トピックス
-------------------------------------*/
#PageTopics.pageIndex{
}

@media print, screen and (min-width: 768px) {
	#PageTopics.pageIndex{
		#Main{
			.listTypeBlog{
				margin-top: 21px;
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageIndex{
	}
}

/*
カテゴリ
-------------------------------------*/
#PageTopics.pageCategory{
}

@media print, screen and (min-width: 768px) {
	#PageTopics.pageCategory{
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageCategory{
	}
}

/*
月別アーカイブ
-------------------------------------*/
#PageTopics.pageMonthly{
}

@media print, screen and (min-width: 768px) {
	#PageTopics.pageMonthly{
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageMonthly{
	}
}

/*
詳細記事
-------------------------------------*/
#PageTopics.pageEntry{
}

@media print, screen and (min-width: 768px) {
	#PageTopics.pageEntry{
		#Main{
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageEntry{
	}
}

@media screen and (-ms-high-contrast: active), print and (-ms-high-contrast: none), screen and (-ms-high-contrast: none) {
	#Page{
		#Main{
			#ContBox02{
				.contSubBox01{
					h4{
						background: transparent;
						color: #6264a1;
					}
				}
			}
		}
	}
}